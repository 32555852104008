import * as Sentry from '@sentry/react'
import { User } from 'app/context/userContext'
import { useVars } from 'app/VarsContext'
import React, { useEffect } from 'react'
import Rox, { Flag, RoxNumber, RoxString } from 'rox-browser'

interface RolloutProviderProps {
  children: React.ReactNode
}

type RolloutFlag = RoxNumber | Flag | RoxString | undefined

export const DEFAULT_EI_START_OFFSET = 0 // Copying: https://github.com/afresh-technologies/afresh-swift-app/blob/6994e3d50fdd227a6e4cee0c0c05b637515c78a7/AfreshApp/State/RoxConfigServiceProvider.swift#L333
export const DEFAULT_EI_INTERVAL = 4 // Copying: https://github.com/afresh-technologies/afresh-swift-app/blob/6994e3d50fdd227a6e4cee0c0c05b637515c78a7/AfreshApp/State/RoxConfigServiceProvider.swift#L334

const webPortalFlags = {
  allowEditOrderGuideItemName: new Rox.Flag(),
  isSingleUserSubscriptionManagementEnabled: new Rox.Flag(),
  showMarginBasedCostReviewInventoryGuide: new Rox.Flag(),
  allowHistoricalInventoryGuides: new Rox.Flag(),
}

/**
 * These flags are tied to the `AfreshApp` namespace
 */
const afreshAppFlags = {
  endingInventoryStartOffsetConfig: new Rox.RoxNumber(DEFAULT_EI_START_OFFSET),
  endingInventoryIntervalConfig: new Rox.RoxNumber(DEFAULT_EI_INTERVAL),
  isEndingInventoryActiveConfig: new Rox.Flag(),
}

/**
 * These flags are tied to the `AppApi` namespace
 */
const appApiFlags = {
  isGuideOrganizationCategoryConfigurationEnabled: new Rox.Flag(false),
  isStoreGroupPortalOrderGuideManagementEnabled: new Rox.Flag(false),
}

export const allFlags = { ...webPortalFlags, ...afreshAppFlags, ...appApiFlags }

const RolloutContext = React.createContext<typeof allFlags | undefined>(
  undefined,
)

function useInitRollout() {
  const { rolloutToken } = useVars()

  useEffect(() => {
    if (!rolloutToken) {
      return
    }

    async function initRollout() {
      try {
        Rox.register('WebPortal', webPortalFlags)
        Rox.register('AfreshApp', afreshAppFlags)
        Rox.register('AppAPI', appApiFlags)
        await Rox.setup(rolloutToken)
      } catch (err) {
        Sentry.captureMessage('Rollout initialization failed', {
          level: 'warning',
        })
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initRollout()
  }, [rolloutToken])

  return allFlags
}

function RolloutProvider({ children }: RolloutProviderProps) {
  const flags = useInitRollout()

  return (
    <RolloutContext.Provider value={flags}>{children}</RolloutContext.Provider>
  )
}

function useFeatureFlags() {
  const context = React.useContext(RolloutContext)

  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a RolloutProvider')
  }

  return context
}

export function setUserForFeatureFlags(user: User) {
  // Data reference: https://github.com/afresh-technologies/afresh-swift-app/blob/6994e3d50fdd227a6e4cee0c0c05b637515c78a7/AfreshApp/Network/Parsing/Models%2BGraphQL.swift#L35-L52
  // Rox properties reference: https://github.com/afresh-technologies/afresh-swift-app/blob/6994e3d50fdd227a6e4cee0c0c05b637515c78a7/AfreshApp/State/RoxConfigServiceProvider.swift#L83-L89
  try {
    Rox.setCustomStringProperty('userID', user.id)
    Rox.setCustomStringProperty('scope', user.userScope)
    if (user.customer?.key) {
      Rox.setCustomStringProperty('customer', user.customer.key)
    }
    if (user.department?.key) {
      Rox.setCustomStringProperty('department', user.department.key)
    }
    if (user.store?.id) {
      Rox.setCustomStringProperty('storeID', user.store.id)
    }
    if (user.store?.businessUnitId) {
      Rox.setCustomStringProperty('businessUnitID', user.store.businessUnitId)
    }
    if (user.store?.orderingGroup) {
      Rox.setCustomStringProperty('orderingGroup', user.store.orderingGroup)
    }

    Rox.fetch()
  } catch (err) {
    Sentry.captureMessage('Failed update feature flags with user properties', {
      level: 'warning',
    })
  }
}

export function setActiveCustomerForFeatureFlags(customerKey: string) {
  try {
    Rox.setCustomStringProperty('customer', customerKey)
  } catch (err) {
    Sentry.captureMessage(
      'Failed update feature flag properties with active customer',
      {
        level: 'warning',
      },
    )
  }
}

// This function overload makes it so when it's used, the
// return type is properly inferred based on the parameter's type
// For example, it returns `boolean` instead of `number | boolean | string | undefined`
function getFlagValue<T extends RolloutFlag>(
  flag: T,
  context?: {},
): T extends Flag
  ? boolean
  : T extends RoxString
  ? string
  : T extends RoxNumber
  ? number
  : undefined

// eslint-disable-next-line no-redeclare
function getFlagValue(
  flag: RolloutFlag,
  context?: {},
): number | boolean | string | undefined {
  if (flag instanceof Flag) {
    return flag.isEnabled(context)
  }

  if (flag instanceof RoxNumber || flag instanceof RoxString) {
    return flag.getValue(context)
  }

  return undefined
}

export { getFlagValue, RolloutProvider, useFeatureFlags }
